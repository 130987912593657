import React from 'react';
import './LEDSign.css';

const LEDSign = () => {
  return (
    <div className="app-container">
      <div id="led-sign">
        <div id="led-text"> JGB GROUP LTD | 1988-2024 | JGB GROUP LTD | 1988-2024 | JGB GROUP LTD | 1988-2024 </div>
        <div id="led-text-short"> JGB GROUP LTD | 1988-2024 </div>
      </div>
    </div>
  );
};

export default LEDSign;