import React from "react";
import { Col } from "react-bootstrap";
import Irina from "./Irina.png"
import ProfileCard from "./ProjectCard"

function Home4() {
  return (
          <Col className="project-card">
            <ProfileCard
              imgProfile={Irina}
              title="Кохановская Ирина"
              title2="ЗАО «ИГБ Групп ЛТД» Финансовый директор"
              description="
              Принимает активное участие в проектах JGB Group с 2001 года на позиции заместителя главного бухгалтера и с 2007 года в статусе финансового директора.             
              Участвует непосредственно в разработке договоров, обсуждении и защите их условий при ведении переговоров с заказчиками, осуществляет дальнейшее сопровождение договоров, контролирует исполнение договорных обязательств.             
              Возглавляет административное, юридическое и финансовое направления в работе компании, координирует взаимодействие всех подразделений JGB Group.
              "
            />
          </Col>
  );
}
export default Home4;
