import React from "react";
import Card from "react-bootstrap/Card";

function ProfileCard(props) {
  return (
    <Card className="project-card-view">
      <Card.Body>
      <Card.Img
        variant="top"
        src={props.imgProfile}
        alt="card-img"
        style={{ width:"200px", marginLeft:"auto", marginRight:"auto" }}
      />
        <Card.Title style={{ paddingBottom: "10px", fontSize: "24px" }}>
          {props.title}
        </Card.Title>
        <Card.Title style={{ paddingBottom: "10px", fontSize: "20px" }}>
          {props.title2}
        </Card.Title>
        <Card.Text style={{ textAlign: "left", paddingBottom: "25px" }}>
          {props.description}
        </Card.Text>
      </Card.Body>
    </Card>
  );
}
export default ProfileCard;
