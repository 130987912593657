import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/test3.gif";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
          <h1
              style={{ paddingBottom:"20px", fontSize: "2.1em" }}
              data-aos="fade-right"
            >
              <span className="primary-header">О компании</span>
            </h1>
            <p className="home-about-body" data-aos="fade-up">
             <div>
              Группа компаний ИГБ была зарегистрирована в Санкт-Петербурге и является группой управляющих компаний. ЗАО «ИГБ Констракшн Лтд» в течение последних 20 лет успешно притворяет в жизнь проекты различной сложности. ИГБ Консультер АБ была основана в Швеции в 1988 году. Компания предоставляет все виды консалтинговых услуг в сфере управления недвижимостью и строительстве, включая проекты «под ключ». В своей деятельности группа компаний ИГБ координирует действия всех участников, задействованных в реализации проектов, в частности, действия согласующих и контролирующих органов, инвесторов, подрядчиков, поставщиков.
             </div>
             <br />
             <div>
              Профессионализм, превосходная внутренняя инфраструктура позволяет выполнять самые высокие требования заказчиков быстро, гибко и надежно. Объединённый Российский и зарубежный опыт работы специалистов ИГБ позволяет эффективно и в кратчайшие сроки реализовывать поставленные задачи. 
             </div>
             <br />
            </p>
          </Col>
          <Col md={4} className="myAvtar" style={{ paddingTop:"50px" }}>
              <img
                data-aos="fade-left"
                src={myImg}
                className="img-fluid"
                alt="JGB Logo"
              />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
