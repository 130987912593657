import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import ProjectCard from "./ProjectCards";
import Tech from "../../Assets/Projects/Tech.png";
import Engine from "../../Assets/Projects/Engine.png";
import Delivery from "../../Assets/Projects/Delivery.png";
import Manage from "../../Assets/Projects/Manage.png";
import Cons from "../../Assets/Projects/Cons.png";
import Contract from "../../Assets/Projects/Contract.png";
import Crane from "../../Assets/Projects/Crane.png";

import "./project.css";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Container>
      <h1
              style={{ fontSize: "2.1em", paddingBottom: "10px" }}
              data-aos="fade-right"
            >
              <span className="primary-header">Услуги</span>
            </h1>
        Мы предлагаем следующие услуги
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
        <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={Manage}
              title="Управление проектом"
              description="У нас есть потенциал для предоставления полного набора услуг по подготовке всех документов, чертежей, обследований, тестов, необходимых для получения согласований, строительства и полного оборудования помещений. Мы разрабатываем правила поведения и организуем тендер на строительные работы, что включает в себя: разработку тендерной документации; предварительный отбор участников тендера; оценка предложений; составление отчета по результатам тендера; подготовка договора с подрядной организацией. Мы осуществляем разработку административно-правовой и технической схемы реализации проекта."
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={Crane}
              title="Строительство"
              description="ИГБ выполняет функции генерального подрядчика и заказчика. Разработка и контроль выполнения графика строительства; технический надзор: контроль над соблюдением подрядной организацией строительных технологий, качества производства работ и требований нормативной документации; обеспечение строительства материалами и оборудованием; контроль качества поступающих на стройплощадку стройматериалов; координация деятельности проектных, строительно-монтажных и других специализированных организаций. Мы отдаем предпочтение привлечению местных специалистов и рабочих, при необходимости привлекаем иностранных специалистов."
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={Tech}
              title="Проектные работы"
              description="Мы понимаем, что требования Заказчика к дизайну и планировке являются существенными с коммерческой и функциональной точки зрения. Опыт предыдущих проектов является таковым. Местный архитектурный вклад контролируется Управляющим самым тщательным образом и является настолько строгой интерпретацией оригинального первоначального дизайна, насколько возможно. Там, где совершенно невозможно избежать изменений или компромиссов, это должно быть сделано с полным знанием предмета и согласованием ведущего архитектора."
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={Cons}
              title="Строительный консалтинг"
              description="Группа компаний ИГБ предоставляет профессиональные услуги в области Строительного Консалтинга. В условиях быстро развивающегося строительного рынка, и тем более в непростые времена экономического кризиса мы готовы предложить максимально эффективные программы по выполнению проектов, начиная от разработки концепции проекта, заканчивая вводом его в эксплуатацию и последующим техническим обслуживанием. При разработке концепций и схем выполнения проектов наши специалисты используют и отечественный и зарубежный опыт. Для успешного выполнения проектов требуется полный и постоянный контроль всех разделов: разработка проектной и сметной документации, финансовый мониторинг, прохождение всех необходимых согласований, проведение тендеров, подбор надёжных Подрядчиков, постоянный контроль качества работ, организация взаимодействия Подрядчиков, Проектировщиков и других участников проекта."
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={Engine}
              title="Инженерное обслуживание"
              description="Любое построенное из зданий представляет собой совокупность тесно взаимосвязанных конструкций и инженерных систем, определяемую функциональным назначением здания. Наши специалисты предлагают тщательно разработанный подход к эксплуатации объектов недвижимости, что позволяет сократить расходы на эксплуатацию и обслуживание зданий. Это не секрет, что после ввода объекта в эксплуатацию, многие системы нуждаются в настройке в соответствии с функциональной нагрузкой, как всего здания, так и отдельных помещений. Так же назначение помещений может меняться в процессе эксплуатации здания, что требует модернизации систем. Кроме обслуживания инженерных систем зданий, немаловажной частью обслуживанию зданий, и особенно это относиться к гостиничным комплексам и бизнес центрам, является поддержание отделки помещений и мебельного фонда в надлежащем состоянии."
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={Contract}
              title="Согласования"
              description="Имеющийся опыт процесса согласования и деловые контакты в согласующих организациях позволяют нам в короткие сроки согласовывать проектную документацию. Мы организуем разработку и согласование архитектурной концепции участка застройки; получение и согласование исходно-разрешительной документации; получение технических условий на подключение к инженерным сетям."
            />
          </Col>     
          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={Delivery}
              title="Поставки"
              description="Мы обладаем большим опытом в вопросах сети снабжения и импорта. Наши услуги включают: поиск товаров, согласование дизайна, подбор образцов, определение необходимого количества, приобретение, составление программы, консолидация, ведение документации, перевозки, растаможивание, доставку на объект, разгрузку, установку оборудования."
            />
          </Col>
        </Row>
      </Container>
      <ScrollToTop />
    </Container>
  );
}

export default Projects;
