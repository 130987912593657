import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import ProjectCard from "./ProjectCard"
import img1 from "./Projects/Angleter.jpg"
import img2 from "./Projects/Astoria.jpg"
import img3 from "./Projects/Azimut.jpg"
import img4 from "./Projects/Complex.jpg"
import img5 from "./Projects/Corintia.jpg"
import img6 from "./Projects/Esper.jpg"
import img7 from "./Projects/Europe.jpg"
import img8 from "./Projects/Holiday.jpg"
import img9 from "./Projects/JGB1.jpg"
import img10 from "./Projects/JGB2.jpg"
import img11 from "./Projects/JGB3.jpg"
import img12 from "./Projects/Next.jpg"
import img13 from "./Projects/Pri.jpg"
import img14 from "./Projects/Sokos.jpg"
import img15 from "./Projects/SokosV.jpg"
import img16 from "./Projects/VGTRK.jpg"
import "./about.css";

function About() {
  return (
    <Container fluid className="about-section">
      <Container>
        <Row style={{ justifyContent: "center" }}>
          <Col
            md={10}
            style={{
              paddingBottom: "",
            }}
          >
            <h1
              style={{ paddingBottom:"10px", fontSize: "2.1em" }}
              data-aos="fade-right"
            >
              <span className="primary-header">Наши проекты</span>
            </h1>
            Компания предлагает вам ознакомиться со списком наших проектов
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={img2}
              title="Астория"
              description="адрес: ул. Большая Морская, д. 39, Санкт-Петербург, Россия;"
              description2="вид работ: полный комплекс работ;"
              description3="бюджет проекта: $ 14 000 000."
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={img1}
              title="Англетер"
              description="адрес: ул. Большая Морская, д. 39, Санкт-Петербург, Россия;"
              description2="вид работ: полный комплекс работ;"
              description3="бюджет проекта: $ 8 000 000."
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={img13}
              title="Жилой комплекс Привилегия"
              description="адрес: Вязовая улица, дом 8, литер А, Санкт-Петербург, Россия;"
              description2="вид работ: проектирование, отделочные работы, монтаж инженерных сетей, поставка материалов;"
              description3="бюджет проекта: 750 000 000 руб."
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={img6}
              title="Esper Club"
              description="адрес: Эсперова, д16/23, Санкт-Петербург, Россия;"
              description2="вид работ: комплекс работ по устройству отделки входной группы, помещений МОП и лестничных клеток;"
              description3="бюджет проекта: 98 000 000 руб."
            />
          </Col>
          <Col md={6} lg={4}  className="project-card">
            <ProjectCard
              imgPath={img12}
              title="Многофункциональный гостиничноофисный комплекс NEXT"
              description="адрес: В. О., Средний пр., д. 87, литер М, Санкт-Петербург, Россия;"
              description2="вид работ: внутренняя отделка мест общественного пользования, апартментов и помещений парковки;"
              description3="бюджет проекта:  116 000 000 руб."
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={img4}
              title="Комплекс малоэтажных многоквартирных жилых домов с административно-бытовым комплексом"
              description="адрес: Санкт-Петербург, пос. Парголово, ул. Вологдина, уч. 6;"
              description2="вид работ: Инженерные изыскания, проектирование;"
              description3="бюджет проекта: 2 500 000 000 руб."
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={img14}
              title="Сокотель. Отель Олимпик Парк"
              description="адрес: Батайский пер., д. 3А, Санкт-Петербург, Россия;"
              description2="вид работ: полный комплекс работ;"
              description3="бюджет проекта: $ 45 000 000."
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={img16}
              title="Всероссийская Государственная Телерадиовещательная Компания"
              description="адрес: Наб.реки Карповки 39, Санкт-Петербург, Россия;"
              description2="вид работ: полный комплекс работ;"
              description3="бюджет проекта: $ 7 000 000."
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={img3}
              title="Азимут Отель Санкт-Петербург"
              description="адрес: Лермонтовский проспект, д. 43/1, Санкт-Петербург, Россия;"
              description2="вид работ: выполнение комплекса отделочных работ в номерном фонде 16–17 этажей и местах общественного пользования;"
              description3="бюджет проекта: 33 000 000 руб."
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={img8}
              title="Сокотель. Холидей Клаб"
              description="адрес: В.О., Биржевой пер., д. 2-4, Санкт-Петербург, Россия;"
              description2="вид работ: отделка;"
              description3="бюджет проекта: $ 80 000."
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={img15}
              title="Сокотель. Сокос Отель Васильевский"
              description="адрес: В.О., 8-я линия, д. 11-13, Санкт-Петербург, Россия;"
              description2="вид работ: отделка;"
              description3="бюджет проекта: $ 5 000 000."
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={img5}
              title="Отель Коринтия Невский Палас"
              description="адрес: Невский проспект, д. 57, Санкт-Петербург, Россия;"
              description2="вид работ: косметический ремонт;"
              description3="бюджет проекта: $ 3 000 000."
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={img11}
              title="Бизнес-центр ИГБ №3"
              description="адрес: ул. Жуковского, д. 63, Санкт-Петербург, Россия;"
              description2="вид работ: полный комплекс работ;"
              description3="бюджет проекта: $ 8 000 000."
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={img10}
              title="Бизнес-центр ИГБ №2"
              description="адрес: Серпуховская ул., д. 10, Санкт-Петербург, Россия;"
              description2="вид работ: полный комплекс работ;"
              description3="бюджет проекта: $ 3 500 000."
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={img9}
              title="Бизнес-центр ИГБ №1"
              description="адрес: Дивенская ул., д. 3, Санкт-Петербург, Россия;"
              description2="вид работ: полный комплекс работ;"
              description3="бюджет проекта: $ 1 500 000."
            />
          </Col>
          <Col md={6} lg={4} className="project-card">
            <ProjectCard
              imgPath={img7}
              title="Гранд Отель Европа"
              description="адрес: Михайловская ул., 1/7, Санкт-Петербург, Россия;"
              description2="вид работ: реализация;"
              description3="бюджет проекта: $ 150 000."
            />
          </Col>
        </Row>
      </Container>
      <ScrollToTop />
    </Container>
  );
}
export default About;
