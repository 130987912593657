import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import Sponsors from "./Sponsors"
import LEDSign from "./LEDSign";
import Home2 from "./Home2";
import Home3 from "./Home3";
import Home4 from "./Home4";
import Type from "./Type";
import "./home.css";
import "./LEDSign.css"

function Home() {
  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Container className="home-content">
          <Row>
            <Col className="home-header">
              <h1 style={{ paddingBottom: 5 }} className="heading">
                Добро пожаловать в{" "}
              </h1>
              <h1 className="heading-name">
                <strong className="main-name"> ИГБ ГРУПП ЛТД</strong>
              </h1>
              <div style={{ padding: 0 }} className="type">
                <Type />
              </div>
              <Col className="QR"></Col>
            </Col>
          </Row>
        </Container>
      </Container>
      <LEDSign />
      <Container className="home2">
        <Home2 />
        <Home3 />
        <Home4 />
        <h1 style={{ paddingTop: "5%", paddingBottom: "2%" }}>
          <span className="primary-header"> Наши партнеры </span>
        </h1>
        <div className="sponsors" data-aos="fade-up" style={{ marginBottom: "10%" }}>
          <Sponsors />
        </div>
        <ScrollToTop />
      </Container>
    </section>
  );
}

export default Home;