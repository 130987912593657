import React from "react";
import Card from "react-bootstrap/Card";

function ProjectCards(props) {
  return (
    <Card className="aproject-card-view">
      <Card.Img className="acard-img-top" src={props.imgPath} alt="card-img" />
      <Card.Body>
        <Card.Title style={{ paddingBottom:"10px", fontSize:"22px" }}>{props.title}</Card.Title>
        <Card.Text style={{ textAlign: "left", paddingBottom:"25px" }}>
          {props.description}
          <div style={{ paddingTop:"10px" }}>{props.description2}</div>
          <div style={{ paddingTop:"10px" }}>{props.description3}</div>
        </Card.Text>
      </Card.Body>
    </Card>
  );
}
export default ProjectCards;
