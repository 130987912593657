import React from "react";
import { Col, Row, Image } from "react-bootstrap";
import Fizika from "./Partners/Fizika.png"
import Euro from "./Partners/Euro.png"
import Glorax from "./Partners/Glorax.png"
import Rocco from "./Partners/Rocco.png"
import Setl from "./Partners/Setl.png"
import Azimut from "./Partners/Azimut.png"

function Techstack() {
  return (
    <Row style={{justifyContent: "center", marginLeft:"auto", marginRight:"auto"}}>
      
      <Col xs={3} md={1} className="tech-icons" style={{backgroundColor:"white"}}>
       <a href="https://fizika.spb.ru/" target="_blank" rel="noreferrer">
        <Image src={Fizika} alt="Fizika" fluid />
       </a>
      </Col>
      

      <Col xs={3} md={1} className="tech-icons">
       <a href="https://esgroup.ru/" target="_blank" rel="noreferrer">
        <Image src={Euro} alt="Custom Image" fluid />
       </a>
      </Col>

      <Col xs={3} md={1} className="tech-icons">
       <a href="https://glorax.com/" target="_blank"rel="noreferrer">
        <Image src={Glorax} alt="Custom Image" fluid />
       </a>
      </Col>

      <Col xs={3} md={1} className="tech-icons">
       <a href="https://www.roccofortehotels.com/ru/" target="_blank" rel="noreferrer">
        <Image src={Rocco} alt="Custom Image" fluid />
       </a> 
      </Col>

      <Col xs={3} md={1} className="tech-icons">
       <a href="https://setlgroup.ru/" target="_blank" rel="noreferrer">
        <Image src={Setl} alt="Custom Image" fluid />
       </a> 
      </Col>

      <Col xs={3} md={1} className="tech-icons">
       <a href="https://azimuthotels.com/ru" target="_blank" rel="noreferrer">
        <Image src={Azimut} alt="Custom Image" fluid />
       </a>
      </Col>

    </Row>
  );
}

export default Techstack;
