import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ScrollToTop from "../ScrollToTop/ScrollToTop";
import ProjectCardTel from "./ProjectCardTel";
import ProjectCardMap from "./ProjectCardMap";
import ProjectCardMail from "./ProjectCardMail";
import Map from "../../Assets/Projects/Map.png";
import Tel from "../../Assets/Projects/Tel.png";
import Mail from "../../Assets/Projects/Mail.png";
import "./project.css";

function Projects() {
  return (
    <Container fluid className="project-section-contact">
      <Container>
      <h1
              style={{ fontSize: "2.1em", paddingBottom: "10px" }}
              data-aos="fade-right"
            >
              <span className="primary-header">Наши контакты</span>
            </h1>
        Мы рады видеть Вас
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
        <Col md={6} lg={4} className="cproject-card">
            <ProjectCardMap
              imgPath={Map}
              title="Локация"
              description="190103, Санкт-Петербург, Троицкий пр., 6в"
            />
          </Col>
          <Col md={6} lg={4} className="cproject-card">
            <ProjectCardTel
              imgPath={Tel}
              title="Телефон"
              description="+7 (812) 313-05-20"
            />
          </Col>
          <Col md={6} lg={4} className="cproject-card">
            <ProjectCardMail
              imgPath={Mail}
              title="Почта"
              description="info@jgb.spb.ru"
            />
          </Col>
        </Row>
      </Container>
      <ScrollToTop />
    </Container>
  );
}

export default Projects;
