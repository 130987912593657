import React from "react";
import { Col } from "react-bootstrap";
import Denis from "./Denis.png"
import ProfileCard from "./ProjectCard"

function Home3() {
  return (
        <Col className="project-card">
            <ProfileCard
              imgProfile={Denis}
              title="Денис Бак "
              title2="ЗАО «ИГБ Групп ЛТД» Генеральный директор"
              description="
              1999 — ЗАО «ИГБ Девелопмент Лтд»/ ЗАО «ИГБ Констракшн Лтд» Принимал участие в проектах JGB Group, таких как реконструкция Гостиничного Комплекса «Астория и Анллетер», «Коринтия Невский Палас», строительство новой гостиницы «Сокос Олимпик Гарден», дооборудование гостиницы «Сокос Отель Васильевский».
              1998-1999 — Сканска Ист Юроп Ои, Санкт-Петербург Принимал участие в строительстве табачной фабрики Филип Моррис, занимая должности инженера по технике безопасности и координатора работ.     
              1997-1998 — Управление СпецСтроя России № 11, Санкт-Петербург Строительство Коттеджного комплекса «Зелёный город» на Крестовском Острове. Строительство Расчётно-Кассового Центра «Южный»       
              1995-1997 — Монолитстрой, Санкт-Петербург Принимал участие в таких проектах по реконструкции, как Британский Торговый Дом (Стокманн), Шведское консульство, строительство табачной фабрики Ротманс.
              "
            />
          </Col>
  );
}
export default Home3;
